import React from 'react';

import {useAuth0} from '@auth0/auth0-react';
import {useQuery} from '@tanstack/react-query';

import {client, createUserApiV1UserUsersPost} from '../../client';
import PageLayout from '../../components/PageLayout/PageLayout';
import UsersList from '../../components/UsersList/UsersList';

const LoggedInLandingPage = () => {
    const {user, getAccessTokenSilently} = useAuth0();

    getAccessTokenSilently().then((token) => {
        client.setConfig({
            // set default base url for requests
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
    });

    const { isPending } = useQuery({
        queryKey: ['getSelf'],
        queryFn: () => createUserApiV1UserUsersPost({
            body: {
                email: user?.email || '',
                name: user?.name || '',
                nickname: user?.nickname || '',
                picture: user?.picture || ''
            }
        })
    });

    return (
        <PageLayout>
            <div>
                <h1>Welcome to Our App, {user?.email}!</h1>
                <p>This is the landing page.</p>
                <UsersList userCreationPending={isPending}/>
            </div>
        </PageLayout>
    );
};

export default LoggedInLandingPage;
