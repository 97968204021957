import { StyledComponent } from '@emotion/styled';
import {
  Container,
  Box,
  Paper,
  Typography,
  styled,
  TypographyProps,
  LinkProps,
  Link,
} from '@mui/material';

import dartyFriends from '../../assets/images/darty-friends.jpg';
import birthday from '../../assets/images/resized-birthday.jpg';
import dancing2Image from '../../assets/images/resized-dancing-2.jpg';
import dancingImage from '../../assets/images/resized-dancing.jpg';
import groupActivity from '../../assets/images/resized-group-activity.jpg';
import laughingInCity from '../../assets/images/resized-laughing-in-city.jpg';
import picnicPeople from '../../assets/images/resized-picnic-people.jpg';
import poolJump from '../../assets/images/resized-pool-jump.jpg';
import firstMsg from '../../assets/images/text-1.svg';
import secondMsg from '../../assets/images/text-2.svg';
import thirdMsg from '../../assets/images/text-3.svg';
import fourthMsg from '../../assets/images/text-4.svg';
import fifthMsg from '../../assets/images/text-5.svg';
import sixthMsg from '../../assets/images/text-6-empty.svg';
import WaitlistSignup from '../WaitlistSignup/WaitlistSignup';

interface ImagePaperProps {
  src: string;
  alt: string;
}

const ChatBubblePaper = styled(({ src, alt, ...props }: ImagePaperProps) => (
  <Box {...props}>
    <img src={src} alt={alt} loading="lazy" />
  </Box>
))({
  overflow: 'hidden',
  '& img': {
    width: '100%',
    height: 'auto',
    display: 'block',
  },
});

const ImagePaper = styled(({ src, alt, ...props }: ImagePaperProps) => (
  <Paper {...props}>
    <img src={src} alt={alt} loading="lazy" />
  </Paper>
))({
  overflow: 'hidden',
  elevation: 8,
  '& img': {
    width: '100%',
    height: 'auto',
    display: 'block',
  },
});

const StyledContainer = styled(Container)({
  marginTop: '32px',
  paddingBottom: '32px',
  paddingLeft: '0px',
  paddingRight: '0px',
});

const HeaderBox = styled(Box)({
  textAlign: 'center',
  marginBottom: '32px',
});

const ItalicisedHeaderText: StyledComponent<TypographyProps> = styled((props: TypographyProps) => (
  <Typography variant="h4" component="h2" {...props} />
))({
  color: 'white',
  fontStyle: 'italic',
  textAlign: 'center',
});

const FirstGridWrapper = styled(Box)({
  display: 'grid',
  gridTemplateColumns: 'repeat(12, 1fr)',
  gap: '10px',
  gridAutoRows: 'minmax(40px, auto)',
  marginBottom: '32px',
  paddingLeft: '16px',
  paddingRight: '16px',
});

const SecondGridWrapper = styled(Box)({
  display: 'grid',
  gridTemplateColumns: 'repeat(12, 1fr)',
  gap: '10px',
  gridAutoRows: 'minmax(10px, auto)',
  marginBottom: '32px',
  paddingLeft: '16px',
  paddingRight: '16px',
});

const MessagesGridWrapper = styled(Box)({
  display: 'grid',
  gridTemplateColumns: 'repeat(12, 1fr)',
  gap: '10px',
  gridAutoRows: 'minmax(10px, auto)',
  marginBottom: '32px',
  paddingLeft: '16px',
  paddingRight: '16px',
});

const LastGridWrapper = styled(Box)({
  display: 'grid',
  gridTemplateColumns: 'repeat(12, 1fr)',
  gap: '10px',
  gridAutoRows: 'minmax(10px, auto)',
  marginBottom: '32px',
  paddingLeft: '16px',
  paddingRight: '16px',
});

const PoolJumpItem = styled(Box)({ gridColumn: '1 / 8', gridRow: '1 / 4' });
const GroupActivityItem = styled(Box)({ gridColumn: '5 / 13', gridRow: '3 / 5' });

const DancingItem = styled(Box)({ gridColumn: '6 / 13', gridRow: '1 / 6', zIndex: 1 });
const Dancing2Item = styled(Box)({ gridColumn: '1 / 8', gridRow: '2 / 5', zIndex: 2 });
const BirthdayItem = styled(Box)({ gridColumn: '3 / 10', gridRow: '5 / 8', zIndex: 1 });

const FirstMsgItem = styled(Box)({ gridColumn: '5 / 13', gridRow: '1 / 2', zIndex: 1 });
const SecondMsgItem = styled(Box)({ gridColumn: '2 / 9', gridRow: '2 / 4', zIndex: 1 });
const ThirdMsgItem = styled(Box)({ gridColumn: '1 / 7', gridRow: '4 / 5', zIndex: 1 });
const FourthMsgItem = styled(Box)({ gridColumn: '8 / 12', gridRow: '4 / 5', zIndex: 1 });
const FifthMsgItem = styled(Box)({ gridColumn: '4 / 9', gridRow: '6 / 7', zIndex: 1 });
const SixthMsgItem = styled(Box)({ gridColumn: '9 / 11', gridRow: '8 / 9', zIndex: 1 });

const PicnicPeopleItem = styled(Box)({ gridColumn: '1 / 8', gridRow: '1 / 6', zIndex: 3 });
const LaughingInCityItem = styled(Box)({ gridColumn: '6 / 13', gridRow: '3 / 6', zIndex: 1 });
const DartyFriendsItem = styled(Box)({ gridColumn: '3 / 10', gridRow: '5 / 8', zIndex: 1 });

const WhiteSection = styled(Container)({
  backgroundColor: 'white',
  width: '100%',
  paddingTop: '40px',
  marginTop: '-140px',
  position: 'relative',
  zIndex: 0,
});

const BigGreenText = styled((props: TypographyProps) => (
  <Typography variant="h3" component="h2" {...props} />
))({
  color: '#2C4A1D',
  textAlign: 'center',
  fontFamily: 'serif',
});

const BigWhiteText = styled((props: TypographyProps) => (
  <Typography variant="h3" component="h2" {...props} />
))({
  color: 'white',
  textAlign: 'center',
  fontFamily: 'serif',
});

const YellowLink = styled((props: LinkProps) => (
  <Link {...props} underline="always" color="inherit" sx={{ typography: 'h5' }} />
))({
  color: '#FFD700',
  display: 'block',
  textAlign: 'center',
  marginTop: '2rem',
  marginBottom: '2rem',
});

const BlockText = styled((props: TypographyProps) => (
  <Typography maxWidth="320px" variant="h5" align="justify" {...props} />
))({
  color: 'white',
  padding: '0 35px',
  margin: '0 auto',
});

const FatBlockText = styled((props: TypographyProps) => (
  <Typography maxWidth="320px" variant="h5" align="justify" {...props} />
))({
  color: 'white',
  margin: '0 auto',
});

export default function MarketingBody() {
  return (
    <StyledContainer maxWidth="md">
      <HeaderBox>
        <ItalicisedHeaderText>
          You didn&apos;t choose your friends for their planning skills
        </ItalicisedHeaderText>
      </HeaderBox>

      <FirstGridWrapper>
        <PoolJumpItem>
          <ImagePaper src={poolJump} alt="Friends jumping into pool" />
        </PoolJumpItem>
        <GroupActivityItem>
          <ImagePaper src={groupActivity} alt="Group of people in organized activity" />
        </GroupActivityItem>
      </FirstGridWrapper>

      <Box paddingBottom="30px">
        <ItalicisedHeaderText>sometimes you regret that</ItalicisedHeaderText>
      </Box>

      <SecondGridWrapper>
        <Dancing2Item>
          <ImagePaper src={dancing2Image} alt="Black and white photograph of people dancing" />
        </Dancing2Item>
        <DancingItem>
          <ImagePaper
            src={dancingImage}
            alt="Another black and white photograph of people dancing"
          />
        </DancingItem>
        <BirthdayItem>
          <ImagePaper src={birthday} alt="Two little girls wearing birthday hats" />
        </BirthdayItem>
      </SecondGridWrapper>
      <WhiteSection sx={{ height: '300px' }}>
        <Container maxWidth="md">
          <BigGreenText sx={{ paddingTop: '120px' }}>Don&apos;t—just get BTL.</BigGreenText>
        </Container>
      </WhiteSection>
      <Container maxWidth="md">
        <YellowLink href={'#signup'} title="Add BTL to your group chat">
          add it to your group chat
        </YellowLink>

        <BlockText sx={{ marginTop: '3rem' }}>
          It&apos;s not your job to make sure that your friends meet, even though it sure feels like
          it sometimes...
        </BlockText>

        <BlockText sx={{ marginTop: '1rem' }}>
          Add BTL to your group text chats and <b>let it do the herding.</b> There&apos;s no app, no
          interface.
        </BlockText>
      </Container>
      <Container sx={{ margin: '0 auto', paddingTop: '5rem' }}>
        <MessagesGridWrapper>
          <FirstMsgItem maxWidth="300px">
            <ChatBubblePaper src={firstMsg} alt="Text message balloon" />
          </FirstMsgItem>
          <SecondMsgItem maxWidth="270px">
            <ChatBubblePaper src={secondMsg} alt="Text message balloon" />
          </SecondMsgItem>
          <ThirdMsgItem maxWidth="280px">
            <ChatBubblePaper src={thirdMsg} alt="Text message balloon" />
          </ThirdMsgItem>
          <FourthMsgItem maxWidth="168px">
            <ChatBubblePaper src={fourthMsg} alt="Text message balloon" />
          </FourthMsgItem>
          <FifthMsgItem maxWidth="220px">
            <ChatBubblePaper src={fifthMsg} alt="Text message balloon" />
          </FifthMsgItem>
          <SixthMsgItem maxWidth="50px">
            <ChatBubblePaper src={sixthMsg} alt="Text message balloon" />
          </SixthMsgItem>
        </MessagesGridWrapper>
      </Container>
      <Container maxWidth="md">
        <YellowLink href={'#signup'} title="Let BTL solve this conundrum">
          let BTL solve this conundrum
        </YellowLink>

        <BlockText sx={{ marginTop: '3rem' }}>
          BTL will tackle all the schedule wrangling, the nudging, the <i>making a plan</i>.
        </BlockText>

        <BlockText sx={{ marginTop: '1rem' }}>
          We’ve designed it to reduce the emotional labor and cognitive load of doing the thing that
          makes us happiest in the end–spending quality time with our loved ones.
        </BlockText>

        <BigWhiteText sx={{ marginTop: '5rem' }}>
          We know you’ve thought about sending a doodle or When2Meet or even a Qualtrics survey to
          your friends, you weirdo.
        </BigWhiteText>

        <YellowLink
          href={'#signup'}
          title="BTL: the easy, social scheduling solution"
          sx={{ marginTop: '3rem' }}
        >
          BTL: the easy, social scheduling solution
        </YellowLink>

        <FatBlockText sx={{ marginTop: '3rem' }} maxWidth="320px">
          Don’t let your type A tendencies ruin your friendships.
        </FatBlockText>

        <FatBlockText sx={{ marginTop: '3rem' }}>
          And don’t let your friends suffer without you.
        </FatBlockText>

        <FatBlockText sx={{ marginTop: '3rem' }}>
          Turn your <i>“we should get together sometime”</i> into an actual get-together without
          clunky software that reminds you of work and/or 2005.
        </FatBlockText>
      </Container>
      <Container sx={{ margin: '0 auto', paddingTop: '5rem' }}>
        <LastGridWrapper>
          <PicnicPeopleItem maxWidth="300px">
            <ChatBubblePaper src={picnicPeople} alt="Text message balloon" />
          </PicnicPeopleItem>
          <LaughingInCityItem maxWidth="270px">
            <ChatBubblePaper src={laughingInCity} alt="Text message balloon" />
          </LaughingInCityItem>
          <DartyFriendsItem maxWidth="280px">
            <ChatBubblePaper src={dartyFriends} alt="Text message balloon" />
          </DartyFriendsItem>
        </LastGridWrapper>
      </Container>
      <Container maxWidth="md">
        <BigWhiteText sx={{ marginTop: '-4rem' }}>
          built by two humans who like socializing, even though its hard sometimes.
        </BigWhiteText>
        <YellowLink href={'#signup'} title="make book club easier" sx={{ marginTop: '3rem' }}>
          make book club easier
        </YellowLink>
      </Container>
      <Container sx={{ paddingTop: '30rem' }} />
      <Container id="signup">
        <WhiteSection sx={{ paddingBottom: '4rem' }}>
          <WaitlistSignup />
        </WhiteSection>
      </Container>
      <Container sx={{ paddingTop: '1rem' }} />
    </StyledContainer>
  );
}
