import React from 'react';

import { Box, Theme, ThemeProvider } from '@mui/material';

import Footer from '../../components/MarketingPage/Footer';
import Header from '../../components/MarketingPage/Header';
import MarketingBody from '../../components/MarketingPage/MarketingBody';

interface MarketingPageProps {
  theme: Theme;
}

function MarketingPage({ theme }: MarketingPageProps) {
  return (
    <ThemeProvider theme={theme}>
      <Box sx={(theme) => ({
        flexGrow: 1,
        backgroundColor: theme.palette.background.default,
        minHeight: '100vh'
      })}>
        <Header />
        <MarketingBody />
        <Footer />
      </Box>
    </ThemeProvider>
  );
}

export default MarketingPage;
