import React, { Component } from 'react';

import { AppBar, Box, Link, Toolbar } from '@mui/material';

import logo from '../../assets/images/btl.svg';

class Header extends Component {
  render() {
    return (
      <AppBar
        position="static"
        elevation={0} // This removes the shadow
        sx={{
          borderBottom: '2px solid white', // This adds the white line
          backgroundColor: '#1B3409', // Keeping the dark green background
          height: '80px',
          paddingTop: '8px',
        }}
      >
        <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <img src={logo} alt="Logo" style={{ width: '100px', height: 'auto' }} />
          <Box>
            <Link
              href={'#signup'}
              color="inherit"
              underline="always"
              sx={{
                typography: 'h5',
              }}
            >
              keep your friends
            </Link>
          </Box>
        </Toolbar>
      </AppBar>
    );
  }
}

export default Header;
