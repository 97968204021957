import { Box, Container, Link, Typography } from '@mui/material';
import '@fontsource/ibm-plex-mono';

const Footer = () => {
  return (
    <Box
      component="footer"
      sx={{
        py: 3,
        px: 2,
        mt: 'auto',
        backgroundColor: 'grey.100',
        borderTop: '1px solid',
        borderColor: 'grey.200'
      }}
    >
      <Container
        maxWidth="lg"
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <Typography
          fontFamily={'"IBM Plex Mono", monospace'}
          fontSize="25px"
          variant="body1"
          sx={{
            letterSpacing: '0.5px',
            color: 'text.primary'
          }}
        >
          a joinery project
        </Typography>

        <Link
          href={'mailto:founders@joinery.io'}
          sx={{
            fontSize: '25px',
            color: 'text.primary',
            textDecoration: 'underline',
            '&:hover': {
              color: 'text.secondary'
            }
          }}
        >
          contact us
        </Link>
      </Container>
    </Box>
  );
};

export default Footer;